<template>
  <v-card>
    <v-card-title primary-title>
      <span v-if="appointments.length > 0">
        Please notify all appointments before eliminating the selected blocks
      </span>
      <span v-if="appointments.length < 1">
        No scheduled appointments are in the selected blocks. <br/>Are you sure you want to
        delete?
      </span>
    </v-card-title>
    <v-card-text>
      <v-row v-if="loadingBlockAppointments">
        <v-col cols="12" sm="12" class="text-center">
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      dense
      :headers="headers"
      :items="appointments"
      class="elevation-1"
      v-if="appointments.length > 0"
    >
      <template v-slot:[`item.datetime_start`]="{ item }">
        <span>{{ getPrettyTime(item.datetime_start) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="error" x-small @click="releaseAppointment(item)" :disabled="releasingAppt"
          >Release</v-btn
        >
      </template>
    </v-data-table>
    <v-card-actions v-if="ranCheck">
      <v-btn color="primary" @click="closeme()"> Cancel </v-btn>
      <v-btn
        color="error"
        :disabled="appointments.length > 0"
        @click="deleteBlocks()"
      >
        Delete Blocks
      </v-btn>
    </v-card-actions>
    
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    blockList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      ranCheck: false,
      appointments: [],
      headers: [
        { text: "Start Date", value: "datetime_start" },
        { text: "Name", value: "employeeFullName" },
        { text: "Phone", value: "phone" },
        { text: "Actions", value: "actions" },
      ]
    };
  },
  methods: {
    ...mapActions("users", ["deleteAppointment"]),
    ...mapActions("vaccineLocations", ["deleteSingle"]),
    ...mapActions("appointmentBlock", ["getMultiBlockAppts", "deleteMultiBlocks"]),
    refreshBlockApptsList() {
      this.getMultiBlockAppts({ blocks: this.blockList.map(b => b.id), ...this.blockList[0] })
          .then((response) => {
            this.appointments = response;
            this.ranCheck = true;
      });
    },
    releaseAppointment: function (item) {
      this.deleteAppointment({ appointmentId: item.id, personId: item.user_id })
          .then((response) => {
           this.refreshBlockApptsList();
      });
    },
    closeme() {
      this.$emit("closeMultiDelete");
    },
    deleteBlocks() {
      if (this.appointments.length < 1) {
        this.deleteMultiBlocks({ blocks: this.blockList.map(b => b.id), ...this.blockList[0] })
            .then((response) => {
              this.$emit("closeMultiDelete");
        });
      }
    },
    convertToLocal: function (date) {
      return moment.utc(date).local();
    },
    getPrettyTime: function (date) {
      return this.convertToLocal(date).format("MMM Do h:mm a");
    },
  },
  computed: {
    ...mapGetters("appointmentBlock", ["loadingBlockAppointments"]),
    ...mapGetters("users", ["releasingAppt"])
  },
  mounted() {

    //length  >0
    if (this.blockList !== null) {
      this.refreshBlockApptsList();
    }
  },
};
</script>
